<template>
  <h1 class="h3 mb-3"><strong>Configuration</strong> Details</h1>
  <div class="row">
    <div class="col-12 col-lg-12 col-xxl-12 d-flex">
      <div class="card flex-fill">
        <div class="card-header mb-2">
          <div class="row">
            <div class="col-12 col-md">
              <h3 class=" mb-3">Instance Config</h3>
              <span class="text-muted">Create or update instances and manage their settings.</span>
            </div>
          </div>
        </div>

        <div class="col-11 align-self-center">
          <hr class="divider" style="border: 1px solid" />
        </div>

        <div class="row container">
          <div class="col-12">
            <div class="row">
              <div class="col-6 mb-4">
                <div class="form-group">
                  <h4>Domain name</h4>
                  <div class="input-group">
                    <input class="form-control" type="text" placeholder="Mobile" />
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="form-group">
                  <h4>Url</h4>
                  <div class="input-group">
                    <input class="form-control" type="text" placeholder="Mobile" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-group">
                <h4>Instance name</h4>
                <div class="input-group">
                  <input class="form-control" type="text" placeholder="Mobile" />
                </div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-group">
                <h4>phone</h4>
                <div class="input-group">
                  <input class="form-control" type="text" placeholder="Mobile" />
                </div>
              </div>
            </div>
            <div class="col-6 col-md-6 mb-4">
              <div class="form-group">
                <h4>Parent domain</h4>
                <select class="form-select" aria-label="Default select example">
                  <option selected>None</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="col-6 mb-4">
              <div class="form-group">
                <h4>Check boxes</h4>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label class="form-check-label" for="flexCheckDefault">
                    Default checkbox
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-select v-model="value1" multiple placeholder="Select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="col-11 align-self-center">
          <hr class="divider" style="border: 1px solid" />
        </div>
        <footer>
          <div class="col-12 mb-2 text-center">
            <button class="btn btn-success col-4 m-3" type="button">Submit</button>
            <button class="btn btn-danger col-4 m-3" type="button">Cancel</button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Configuration',
    data() {
      return {
        options: [
          {
            value: 'Option1',
            label: 'Option1',
          },
          {
            value: 'Option2',
            label: 'Option2',
          },
          {
            value: 'Option3',
            label: 'Option3',
          },
          {
            value: 'Option4',
            label: 'Option4',
          },
          {
            value: 'Option5',
            label: 'Option5',
          },
        ],
        value1: [],
      }
    },
  }
</script>

<style scoped></style>
